import { saveAs } from 'file-saver';
import moment from 'moment-timezone';
import getFilePathsFromManifest from 'WebpackManifestHelper';
import { fetchBrandConfig } from 'AppBranding';
import { getProgramInclusion, relevantProgramInclusionsForTranscriptDownload } from 'Users';
import { hasGraduated, willNotGraduate } from 'ProgramInclusion';
import { formatScore } from 'FormatScore';
import angularModule from './settings_module';

// High-level ticket references:
//      https://trello.com/c/19VZXvuB
//      https://trello.com/c/1qojXmjm

angularModule.factory('DownloadableStudentDocsHelper', [
    '$injector',

    $injector => {
        const SuperModel = $injector.get('SuperModel');
        const $q = $injector.get('$q');
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');
        const $ocLazyLoad = $injector.get('$ocLazyLoad');
        const safeDigest = $injector.get('safeDigest');
        const Cohort = $injector.get('Cohort');
        const Playlist = $injector.get('Playlist');
        const Stream = $injector.get('Lesson.Stream');
        const $rootScope = $injector.get('$rootScope');
        const TextToImageHelper = $injector.get('TextToImageHelper');
        const StreamProgress = $injector.get('Lesson.StreamProgress');

        return SuperModel.subclass(() => ({
            initialize() {
                this.textToImageHelper = new TextToImageHelper();
            },

            /**
             * @param format - Either 'digital' or 'printable'.
             * @param type - Either 'official' or 'unofficial', but
             *      cannot be 'unofficial' when `format` is 'printable'.
             */
            downloadTranscript(user, format, type) {
                return $q
                    .all(getFilePathsFromManifest('certificates', 'js').map(path => $ocLazyLoad.load(path)))
                    .then(() => this._buildUserTranscriptData(user, format, type))
                    .then(data => this._performDownload(data, type));
            },

            /**
             * @param document_type - Either 'enrollment_verification_letter' or 'progress_report'.
             */
            downloadSupplementalDocument(user, document_type, programInclusionId) {
                return $http
                    .get(
                        `${$window.ENDPOINT_ROOT}/api/users/${user.id}/${document_type}?program_inclusion_id=${programInclusionId}`,
                        {
                            responseType: 'arraybuffer',
                        },
                    )
                    .then(response => {
                        const file = new Blob([response.data], { type: 'application/zip' });
                        try {
                            const fileName = `${[document_type, user.name].join('-')}.docx`;

                            saveAs(file, fileName);
                            safeDigest($rootScope);
                        } catch (e) {
                            $injector
                                .get('ErrorLogService')
                                .notify(`Failed to write ${document_type} blob data to window!`, e);
                            throw e;
                        }
                    });
            },

            // Note: This could be optimized further for regular users by using the playlists and
            // streams already cached from the LearnerContentAccess calls, but that would require enough
            // work that I decided not to for something that should not be used frequently. It is
            // actually probably better from an accidental breakage perspective to not have this tied to
            // the caching logic, and since we want to use English titles regardless of user's locale we'd
            // have to make sure that the English title always gets returned in the LearnerContentAccess calls.
            _buildUserTranscriptData(fullUser, format, type) {
                const self = this;
                let playlists;
                let serverTranscriptData;
                const streamPackIdsByCohort = {};
                const streamPackIdsFromPlaylistsByCohort = {};
                const sortedDedupedCompletedSpecializationPlaylistsByCohort = {};
                const streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort = {};
                const cachedPageRequestResponses = {};

                // Function to chop down the stream_entries such that no streams are repeated if they're duplicated among multiple playlists.
                // We're running this on the sorted playlists, required first, so that earlier playlists take precedence as owner
                // of the stream. As we run it we'll track which streams are in a playlist, both for use as we iterate over the sorted playlists
                // and to later determine which other required streams, not in a playlist, to display as a separate transcript item.
                function dedupeStreamEntries(playlist, cohort) {
                    // Dedupe the stream_entries into a temporary variable
                    playlist.$$dedupedStreamEntries = playlist.stream_entries.filter(
                        streamEntry => !streamPackIdsFromPlaylistsByCohort[cohort.id][streamEntry.locale_pack_id],
                    );

                    // Add the deduped locale_pack_ids to some mappings for tracking
                    playlist.$$dedupedStreamEntries.forEach(streamEntry => {
                        streamPackIdsFromPlaylistsByCohort[cohort.id][streamEntry.locale_pack_id] = true;
                        streamPackIdsByCohort[cohort.id][streamEntry.locale_pack_id] = true;
                    });

                    return playlist;
                }

                // Grab the requested cohort(s) and a few pieces of data from the backend that we calculate in mat views
                return (
                    $http
                        .get(`${$window.ENDPOINT_ROOT}/api/users/show_transcript_data.json`, {
                            params: {
                                user_id: fullUser.id,
                                'cohort_ids[]': relevantProgramInclusionsForTranscriptDownload(fullUser).map(
                                    pi => pi.cohortId,
                                ),
                            },
                        })
                        .then(response => {
                            serverTranscriptData = response.data.transcript_data;
                            serverTranscriptData.forEach(data => {
                                streamPackIdsByCohort[data.cohort.id] = {};
                                streamPackIdsFromPlaylistsByCohort[data.cohort.id] = {};
                                streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort[data.cohort.id] =
                                    {};
                                data.cohort = Cohort.new(data.cohort);
                            });

                            // Get ALL of the playlists for the cohort(s).
                            const playlistPackIds = serverTranscriptData.reduce(
                                (packIds, data) => packIds.concat(data.cohort.playlistPackIds),
                                [],
                            );
                            return Playlist.index({
                                user_id: fullUser.id,
                                filters: {
                                    locale_pack_id: [...new Set(playlistPackIds)],
                                    locale: 'en',
                                },
                                'fields[]': ['id', 'title', 'stream_entries', 'locale_pack', 'percent_complete'],
                            });
                        })
                        .then(response => {
                            playlists = response.result;

                            const allRequiredStreamPackIds = [
                                ...new Set(
                                    serverTranscriptData.reduce((packIds, data) => {
                                        data.required_stream_locale_pack_ids.forEach(packId => {
                                            streamPackIdsByCohort[data.cohort.id][packId] = true;
                                        });
                                        return packIds.concat(data.required_stream_locale_pack_ids);
                                    }, []),
                                ),
                            ];
                            const allOptionalStreamPackIds = [
                                ...new Set(
                                    serverTranscriptData.reduce((packIds, data) => {
                                        data.optional_stream_locale_pack_ids.forEach(packId => {
                                            streamPackIdsByCohort[data.cohort.id][packId] = true;
                                        });
                                        return packIds.concat(data.optional_stream_locale_pack_ids);
                                    }, []),
                                ),
                            ];
                            const allNoncurriculumStreamPackIds = [
                                ...new Set(
                                    serverTranscriptData.reduce((packIds, data) => {
                                        data.noncurriculum_stream_locale_pack_ids.forEach(packId => {
                                            streamPackIdsByCohort[data.cohort.id][packId] = true;
                                        });
                                        return packIds.concat(data.noncurriculum_stream_locale_pack_ids);
                                    }, []),
                                ),
                            ];

                            // Specialization playlists are sorted by their index in the specialization_playlist_pack_ids.
                            // Also filter to only the ones complete. We'll bucket any complete courses from an
                            // incomplete specialization playlist in the optional courses bucket.
                            serverTranscriptData.forEach(data => {
                                const cohort = data.cohort;
                                sortedDedupedCompletedSpecializationPlaylistsByCohort[cohort.id] =
                                    this._getSortedPlaylists(playlists, cohort.specialization_playlist_pack_ids)
                                        .filter(playlist => playlist.percent_complete === 1)
                                        .map(playlist => dedupeStreamEntries(playlist, cohort));

                                sortedDedupedCompletedSpecializationPlaylistsByCohort[cohort.id]
                                    .map(playlist => playlist.$$dedupedStreamEntries)
                                    .flat(Infinity)
                                    .forEach(streamEntry => {
                                        streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort[
                                            cohort.id
                                        ][streamEntry.locale_pack_id] = true;
                                    });
                            });

                            const allStreamPackIdsFromCompletedSpecializations = [
                                ...new Set(
                                    Object.keys(streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort)
                                        .map(cohortId =>
                                            Object.keys(
                                                streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort[
                                                    cohortId
                                                ],
                                            ),
                                        )
                                        .flat(),
                                ),
                            ];
                            const allStreamPackIds = [
                                ...new Set(
                                    allRequiredStreamPackIds
                                        .concat(allOptionalStreamPackIds)
                                        .concat(allStreamPackIdsFromCompletedSpecializations)
                                        .concat(allNoncurriculumStreamPackIds),
                                ),
                            ];

                            return Stream.index({
                                include_progress: true,
                                include_transcript_data: true,
                                'fields[]': [
                                    'id',
                                    'title',
                                    'lessons',
                                    'chapters',
                                    'image',
                                    'locale_pack',
                                    'lesson_streams_progress',
                                    'time_limit_hours',
                                    'exam',
                                ],
                                'lesson_fields[]': [
                                    'id',
                                    'title',
                                    'assessment',
                                    'locale_pack',
                                    'lesson_progress',
                                    'test',
                                ],
                                user_id: fullUser.id,
                                filters: {
                                    locale_pack_id: allStreamPackIds,
                                    // Force English for display purposes
                                    locale: 'en',
                                    // Explicitly pass this as false - StreamController will default it to true for index
                                    // calls if it isn't passed, which results in the non-En streams being filtered from
                                    // the controller response due to the nature of how the joins are constructed.
                                    // In the future, when and if we support different languages in transcripts, we'll
                                    // need to revisit this and refactor the controller to the do the correct thing.
                                    // See also: https://trello.com/c/A5xbtgdm
                                    in_users_locale_or_en: false,

                                    // StreamsAccessMixin will filter on the user's pending or success cohorts unless we set this to false.
                                    // See also: https://trello.com/c/Re5tkDHX
                                    // Note: this is safe to do because, contrary to being in the Settings module, this feature is only available to admins
                                    user_can_see: false,
                                },
                            });
                        })
                        // eslint-disable-next-line complexity
                        .then(async response => {
                            const allStreams = response.result;
                            const transcriptData = [];

                            for (let i = 0; i < serverTranscriptData.length; i++) {
                                const data = serverTranscriptData[i];
                                const cohort = data.cohort;
                                const streamsToCurriculumTypeMap = {
                                    required: [],
                                    completedSpecialization: [],
                                    elective: [],
                                };
                                const streamsByLocalePackId = _.keyBy(allStreams, 'localePackId');

                                // Required playlists are sorted by their index in concentrationPlaylistPackIds
                                const sortedDedupedConcentrationPlaylists = this._getSortedPlaylists(
                                    playlists,
                                    cohort.concentrationPlaylistPackIds.filter(
                                        playlistPackId =>
                                            // Remove the foundations playlist so its courses will flow into either their
                                            // respective concentration or electives, rather than a beginning entry at the top
                                            playlistPackId !== cohort.foundationsPlaylistLocalePackId,
                                    ),
                                ).map(playlist => dedupeStreamEntries(playlist, cohort));

                                const streamPackIdsFromConcentrationPlaylists = [
                                    ...new Set(
                                        sortedDedupedConcentrationPlaylists
                                            .map(playlist => playlist.$$dedupedStreamEntries)
                                            .flat(Infinity)
                                            .map(streamEntry => streamEntry.locale_pack_id),
                                    ),
                                ];

                                allStreams.forEach(stream => {
                                    if (!streamPackIdsByCohort[cohort.id][stream.localePackId]) return;

                                    const isRequiredStream = data.required_stream_locale_pack_ids.includes(
                                        stream.localePackId,
                                    );
                                    const isCompletedSpecializationStream =
                                        !!streamPackIdsFromSortedDedupedCompletedSpecializationPlaylistsByCohort[
                                            cohort.id
                                        ][stream.localePackId];
                                    const isConcentrationStream = streamPackIdsFromConcentrationPlaylists.includes(
                                        stream.localePackId,
                                    );

                                    // I write it this way because it is possible to have a complete specialization stream that is
                                    // also required, so put a stream like that in both buckets
                                    if (isRequiredStream || isCompletedSpecializationStream) {
                                        if (isRequiredStream) {
                                            streamsToCurriculumTypeMap.required.push(stream);
                                        }
                                        if (isCompletedSpecializationStream) {
                                            streamsToCurriculumTypeMap.completedSpecialization.push(stream);
                                        }
                                    } else if (!isConcentrationStream) {
                                        // Any course not in a concentration or complete specialization playlist is deemed elective
                                        // including noncurriculum streams
                                        streamsToCurriculumTypeMap.elective.push(stream);
                                    }
                                    // NOTE: a stream that is not required, not in a specialization but IS in a
                                    // concentration stream is not added to streamsToCurriculumTypeMap.  However, that stream
                                    // will still show up in the course schedule section, since we look in the
                                    // concentration playlists to find which streams to put there. (as of 2021/01/29,
                                    // this was the case with Customer Discovery in the EMBA curriculum. That stream
                                    // is in the foundations concentration, but not required in the schedule.)
                                });

                                // Sort required streams by the order they appear in the periods if the cohort
                                // supports a schedule, or the title otherwise. Do a sort on the title in both cases since
                                // it is possible to have required streams not in the periods. E.g., a stream is in the foundations
                                // playlist and thus required, but isn't actually specified in the periods. This happened with Blue
                                // Ocean Strategy in EMBA1).
                                let sortedRequiredStreams = _.sortBy(
                                    streamsToCurriculumTypeMap.required,
                                    stream => stream.title,
                                );

                                if (cohort.supportsSchedule) {
                                    const requiredStreamPackIdsFromPeriods =
                                        cohort.getRequiredStreamPackIdsFromPeriods();
                                    sortedRequiredStreams = _.sortBy(sortedRequiredStreams, stream => {
                                        // Make sure we put streams not found in the periods at the end of the list
                                        const index = requiredStreamPackIdsFromPeriods.indexOf(stream.localePackId);
                                        return index > -1 ? index : Number.MAX_SAFE_INTEGER;
                                    });
                                }

                                // Sort elective streams by their title
                                // Note: We are ensuring that the elective stream is not required in a period or included in
                                // a specialization playlist.
                                const sortedElectiveStreams = _.sortBy(
                                    streamsToCurriculumTypeMap.elective,
                                    stream => stream.title,
                                );

                                let sortedDedupedConcentrationPlaylistTranscriptEntries =
                                    this._getPlaylistTranscriptEntries(
                                        sortedDedupedConcentrationPlaylists,
                                        streamsByLocalePackId,
                                        cohort.graduationDate,
                                    );

                                const sortedDedupedCompletedSpecializationPlaylistTranscriptEntries =
                                    this._getPlaylistTranscriptEntries(
                                        sortedDedupedCompletedSpecializationPlaylistsByCohort[cohort.id],
                                        streamsByLocalePackId,
                                        cohort.graduationDate,
                                    );

                                // Filter out any required playlists with no stream complete so that we don't have an empty entry
                                // in a transcript for a user who hasn't graduated
                                sortedDedupedConcentrationPlaylistTranscriptEntries =
                                    sortedDedupedConcentrationPlaylistTranscriptEntries.filter(
                                        playlistEntry => playlistEntry.streamEntries.length > 0,
                                    );

                                // Calculate the remaining required streams not in a playlist for placing in a special top-level
                                // section alongside playlists
                                const sortedRequiredStreamsNotInPlaylist = sortedRequiredStreams.filter(
                                    stream => !streamPackIdsFromPlaylistsByCohort[cohort.id][stream.localePackId],
                                );

                                // We don't want to display ungraded projects
                                const gradedProjectProgresses = data.project_progresses_with_title.filter(
                                    progress => progress.graded,
                                );

                                const sortedProjectProgresses = _.sortBy(
                                    gradedProjectProgresses,
                                    progress => progress.updated_at,
                                );

                                // Find the Capstone project progress if one exists
                                const capstoneProjectProgress = sortedProjectProgresses.find(
                                    progress => progress.project_type === 'capstone',
                                );

                                // If we have a Capstone, push its progress into the sortedCapstoneProjectProgresses array and find it's related
                                // presentation project progress. This might not have 'capstone' in the title or requirement_identifier.
                                // We should be safe to use the main Capstone project's requirement_identifier to locate the presentation project.
                                let capstonePresentationProjectProgress;
                                const sortedCapstoneProjectProgresses = [];
                                if (capstoneProjectProgress) {
                                    sortedCapstoneProjectProgresses.push(capstoneProjectProgress);
                                    capstonePresentationProjectProgress = sortedProjectProgresses.find(
                                        progress =>
                                            progress.id !== capstoneProjectProgress.id &&
                                            progress.requirement_identifier.includes(
                                                capstoneProjectProgress.requirement_identifier,
                                            ),
                                    );

                                    // Conditionally push the presentation progress to the capstone array. We don't want `undefined` in the array.
                                    if (capstonePresentationProjectProgress) {
                                        sortedCapstoneProjectProgresses.push(capstonePresentationProjectProgress);
                                    }
                                }

                                // Once all Capstone related progresses are identified, we can filter all project progresses and move
                                // non-Capstone items into their own array.
                                const sortedNonCapstoneProjectProgresses = sortedProjectProgresses.filter(
                                    progress =>
                                        !sortedCapstoneProjectProgresses
                                            .map(projectProgress => projectProgress.id)
                                            .includes(progress.id),
                                );

                                const transcriptDatum = {
                                    branding: cohort.branding,
                                    sortedDedupedConcentrationPlaylistTranscriptEntries,
                                    sortedDedupedCompletedSpecializationPlaylistTranscriptEntries,
                                    sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist:
                                        self._getStreamTranscriptEntries(sortedRequiredStreamsNotInPlaylist),
                                    sortedCompleteElectiveStreamTranscriptEntries:
                                        self._getStreamTranscriptEntries(sortedElectiveStreams),
                                    sortedNonCapstoneProjectProgresses,
                                    sortedCapstoneProjectProgresses,
                                    capstoneProjectProgress,
                                    finalScore: data.final_score,
                                    creditHours: cohort.credit_hours,
                                    transcriptProgramTitle: cohort.transcriptProgramTitle,
                                    transcriptSectionTitleDegree: cohort.transcriptSectionTitleDegree,
                                    foundationsStreamPackIds: cohort.foundations_lesson_stream_locale_pack_ids,
                                    preferStrictSmartCaseScore: cohort.preferStrictSmartcaseScore,
                                    ...self._graduationInfo(fullUser, cohort),
                                };

                                const includeBackPage = format !== 'printable';
                                const requests = [];
                                const isUnofficial = type === 'unofficial';
                                const isDigital = format === 'digital';
                                const isPrintable = format === 'printable';
                                let frontPageImagePath;
                                let backPageImagePath;

                                const brandConfig = fetchBrandConfig(transcriptDatum.branding);
                                const {
                                    digitalFrontFull,
                                    digitalFrontFullUnofficial,
                                    digitalFrontPartial,
                                    digitalFrontPartialUnofficial,
                                    digitalBack,
                                    digitalBackUnofficial,
                                    printableFrontFull,
                                    printableFrontPartial,
                                    printableBack,
                                } = brandConfig.transcriptImages;

                                if (isPrintable && isUnofficial) {
                                    throw new Error('Printable unofficial transcripts are not available');
                                } else if (transcriptDatum.graduated && isDigital) {
                                    frontPageImagePath =
                                        isUnofficial && digitalFrontFullUnofficial
                                            ? digitalFrontFullUnofficial
                                            : digitalFrontFull;
                                    backPageImagePath =
                                        isUnofficial && digitalBackUnofficial ? digitalBackUnofficial : digitalBack;
                                } else if (!transcriptDatum.graduated && isDigital) {
                                    frontPageImagePath =
                                        isUnofficial && digitalFrontPartialUnofficial
                                            ? digitalFrontPartialUnofficial
                                            : digitalFrontPartial;
                                    backPageImagePath =
                                        isUnofficial && digitalBackUnofficial ? digitalBackUnofficial : digitalBack;
                                } else if (transcriptDatum.graduated && isPrintable) {
                                    frontPageImagePath = printableFrontFull;
                                    backPageImagePath = printableBack;
                                } else if (!transcriptDatum.graduated && isPrintable) {
                                    frontPageImagePath = printableFrontPartial;
                                    backPageImagePath = printableBack;
                                } else {
                                    throw new Error(`${format} format not supported!`);
                                }

                                // get front page background image
                                if (!cachedPageRequestResponses[frontPageImagePath]) {
                                    requests.push(
                                        $http
                                            .get(window.ENDPOINT_ROOT + frontPageImagePath, {
                                                responseType: 'arraybuffer',
                                            })
                                            .then(pageResponse => {
                                                cachedPageRequestResponses[frontPageImagePath] = pageResponse;
                                            }),
                                    );
                                }

                                // get back page
                                if (!cachedPageRequestResponses.backPageImage && includeBackPage) {
                                    requests.push(
                                        $http
                                            .get(window.ENDPOINT_ROOT + backPageImagePath, {
                                                responseType: 'arraybuffer',
                                            })
                                            .then(pageResponse => {
                                                cachedPageRequestResponses.backPageImage = pageResponse;
                                            }),
                                    );
                                }

                                // eslint-disable-next-line no-await-in-loop
                                await $q.all(requests);

                                transcriptDatum.frontPageImage = cachedPageRequestResponses[frontPageImagePath];
                                transcriptData.push(transcriptDatum);
                            }

                            return {
                                name: fullUser.name,
                                email: fullUser.email,
                                studentId: fullUser.studentId,
                                birthdate: fullUser.birthdate,
                                backPageImage: cachedPageRequestResponses.backPageImage,
                                transcriptData,
                            };
                        })
                );
            },

            _graduationInfo(fullUser, transcriptCohort) {
                const programInclusion = getProgramInclusion(fullUser, { cohortId: transcriptCohort.id });
                let graduationStatusText = 'Enrolled';
                let graduationDateText = 'In-Progress';
                let graduated = false;

                if (willNotGraduate(programInclusion)) {
                    graduationStatusText = 'Will Not Graduate';
                    graduationDateText = 'N/A';
                } else if (hasGraduated(programInclusion)) {
                    graduationStatusText = 'Graduated';
                    graduationDateText = moment(programInclusion.graduatedAt * 1000).format('MMMM D, YYYY');
                    graduated = true;
                }

                return { graduationStatusText, graduationDateText, graduated };
            },

            _getSortedPlaylists(playlists, playlistPackIds) {
                return playlists
                    .filter(playlist => playlistPackIds.includes(playlist.localePackId))
                    .sort(
                        (playlist, nextPlaylist) =>
                            playlistPackIds.indexOf(playlist.localePackId) -
                            playlistPackIds.indexOf(nextPlaylist.localePackId),
                    );
            },

            _getPlaylistTranscriptEntries(sortedPlaylists, streamsByLocalePackId, graduationDate) {
                // Build out playlist transcript entries for each of the playlists
                const playlistTranscriptEntries = [];

                // Build each playlist's stream transcript entries
                sortedPlaylists.forEach(playlist => {
                    const streams = [];

                    playlist.$$dedupedStreamEntries.forEach(streamEntry => {
                        streams.push(streamsByLocalePackId[streamEntry.locale_pack_id]);
                    });

                    const playlistEntry = {
                        title: playlist.title,
                        streamEntries: this._getStreamTranscriptEntries(streams),
                    };

                    // Course codes become consistent with cycle 38
                    if (graduationDate >= new Date('2022/10/18')) {
                        Object.assign(playlistEntry, {
                            course_code_prefix: playlist.locale_pack.course_code_prefix,
                            course_code: playlist.locale_pack.course_code,
                        });
                    }

                    playlistTranscriptEntries.push(playlistEntry);
                });

                return playlistTranscriptEntries;
            },

            _getStreamTranscriptEntries(streams) {
                // Remove any uncompleted streams
                streams = streams.filter(stream => stream.complete);

                // Remove any streams with "Exam Practice" or "Coffee Break" in the title
                streams = streams.filter(stream => {
                    const lowerTitle = stream.title.toLowerCase();
                    return !(
                        lowerTitle.includes('exam practice') ||
                        lowerTitle.includes('coffee break') ||
                        lowerTitle.includes('photography basics') ||
                        lowerTitle.includes('why blended learning matters')
                    );
                });

                // Remove any streams that were artificially completed due to
                // the exam being completed in a prior cohort, and for which the user
                // didn't take the initiative to go complete themselves (we unset waiver
                // if they do)
                // See https://trello.com/c/6xyHPQuo
                streams = streams.filter(
                    stream => stream.progressWaiver !== StreamProgress.WAIVER_EXAM_ALREADY_COMPLETED,
                );

                return streams.map(stream => ({
                    title: stream.title,
                    grade: stream.grade,
                    localePackId: stream.localePackId,
                }));
            },

            // eslint-disable-next-line complexity
            async _performDownload(userTranscriptData, type) {
                const self = this;
                const deferred = userTranscriptData.transcriptData.map(_td => $q.defer());
                const promises = deferred.map(d => d.promise);
                const isUnofficial = type === 'unofficial';
                const textColor = !isUnofficial ? '#000000' : '#666665';
                const STANDARD_FONT_SIZE = 9;
                const LARGER_FONT_SIZE = 10;

                // create the pdfkit Document
                // const doc = new $window.PDFDocument({ autoFirstPage: false });
                const doc = new $window.PDFDocument({
                    size: [612, 793],
                    margin: 0,
                });
                const pdfStream = doc.pipe($window.blobStream());

                for (let i = 0; i < userTranscriptData.transcriptData.length; i++) {
                    const transcriptData = userTranscriptData.transcriptData[i];

                    // Order matters and there doesn't seem to be a way to specify layers so used the cached
                    // assets to assemble the PDF here in the correct order.

                    if (i !== 0) {
                        doc.addPage();
                    }

                    // set the background
                    doc.image(transcriptData.frontPageImage.data, 0, 0, {
                        width: 612,
                        height: 793,
                    });

                    // header text
                    const headerX = 200;
                    doc.font('Courier-Bold').fontSize(STANDARD_FONT_SIZE).fillColor(textColor);

                    // Conditionally add the name in the header as text or an image depending
                    // on if it contains characters outside of extended ASCII
                    if (self.textToImageHelper.isASCII(userTranscriptData.name)) {
                        doc.text(userTranscriptData.name, headerX, 33, {
                            width: 215,
                        });
                    } else {
                        self.textToImageHelper.addImageTextToPdf(doc, userTranscriptData.name, headerX, 33, {
                            width: 215,
                            fontSize: STANDARD_FONT_SIZE,
                            font: 'Courier-Bold',
                        });
                    }

                    doc.text(`DOB: ${moment(userTranscriptData.birthdate).format('MM/DD/YYYY')}`)
                        .text(`Student ID: ${userTranscriptData.studentId}`)
                        .text(`Date Issued: ${moment(new Date()).format('MMMM D, YYYY')}`);

                    // ***********************************************
                    // Middle left header info
                    // ***********************************************

                    const programInfoSectionX = 43;
                    const programInfoSectionY = 112;

                    doc.text(
                        `Program: ${transcriptData.transcriptProgramTitle}`,
                        programInfoSectionX,
                        programInfoSectionY,
                    )
                        .text(`Status: ${transcriptData.graduationStatusText}`)
                        .text(`Graduation Date: ${transcriptData.graduationDateText}`);

                    if (transcriptData.graduated) {
                        // Note: Only MBA23 / EMBA18 and on have credit hours
                        if (transcriptData.creditHours) {
                            doc.text(`Total Credit Hours: ${transcriptData.creditHours}`);
                        }

                        doc.text(
                            `Cumulative Final Score: ${this._formatScore({
                                grade: transcriptData.finalScore,
                                isFinalScore: true,
                            })}%`,
                        ).moveDown();
                    }

                    if (
                        userTranscriptData.transcriptData.length > 1 &&
                        i !== userTranscriptData.transcriptData.length - 1
                    ) {
                        doc.text('**see following pages for additional degree enrollment');
                    }

                    // Absolute position values derived from the mock
                    const entryWidth = 284;
                    const entryScoreWidth = 26;
                    const entryTitleWidth = 284 - entryScoreWidth - 5; // give the title a little right padding
                    const entryTabWidth = 5;
                    const columnTopY = 216;
                    const leftColumnStartX = 18;
                    const leftColumnScoreX = leftColumnStartX + entryWidth - entryScoreWidth;
                    const rightColumnStartX = 315;
                    const rightColumnScoreX = rightColumnStartX + entryWidth - entryScoreWidth;
                    const leftColumnWrapPoint = 739;

                    // Position tracking for our manual wrapping into the right column
                    let leftColumnHasWrapped = false;
                    let runningColumnStartX = leftColumnStartX;
                    let runningColumnScoreX = leftColumnScoreX;

                    const ENTRY_FONT_SIZE = this._getEntryFontSize(transcriptData);

                    const checkWrapping = function checkWrapping() {
                        if (doc.y > leftColumnWrapPoint && !leftColumnHasWrapped) {
                            doc.text('', rightColumnStartX, columnTopY);
                            leftColumnHasWrapped = true;
                            runningColumnStartX = rightColumnStartX;
                            runningColumnScoreX = rightColumnScoreX;
                        }
                    };

                    const writeEntryWithGrade = function writeEntryWithGrade(
                        entry,
                        isProject = false,
                        markAsPassed = false,
                    ) {
                        doc.text(`${entry.title}`, runningColumnStartX + entryTabWidth, doc.y, {
                            width: entryTitleWidth,
                        });
                        doc.moveUp();

                        let grade;
                        if (markAsPassed) {
                            grade = self._formatScore({ isUnpassed: false });
                        } else if (isProject) {
                            grade = self._formatScore({ isProject, isUnpassed: entry.unpassed });
                        } else {
                            grade = self._formatScore({ grade: entry.grade });
                        }

                        doc.text(grade, runningColumnScoreX);
                    };

                    // ***********************************************
                    // Curriculum courses list
                    //
                    // The list of courses that are in concentration playlists or required in the schedule, organized by playlist and in the same order
                    // as they area in the playlist collection. For courses that are in the schedule but not
                    // in a playlist, we'll put them at the end as top-level entries.
                    // ***********************************************

                    if (transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.length > 0) {
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .fillColor(textColor)
                            .text(
                                self._formatSectionTitle('COMPLETED COURSEWORK', transcriptData),
                                leftColumnStartX,
                                columnTopY,
                            );

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.forEach(playlistEntry => {
                            checkWrapping();
                            doc.text(
                                this._formatPlaylistTitle(playlistEntry).toUpperCase(),
                                runningColumnStartX,
                            ).moveDown(0);

                            playlistEntry.streamEntries.forEach(streamEntry => {
                                checkWrapping();
                                const markAsPassed = this._omitEntryFromGrade(
                                    transcriptData.preferStrictSmartCaseScore,
                                    transcriptData.foundationsStreamPackIds,
                                    streamEntry,
                                );
                                writeEntryWithGrade(streamEntry, false, markAsPassed);
                            });

                            doc.moveDown(0.25);
                        });

                        // Capstone projects are included under a special 'CAPSTONE' category
                        if (transcriptData.sortedCapstoneProjectProgresses.length > 0) {
                            const capstonePlaylist = {
                                title: 'CAPSTONE',
                                course_code_prefix: transcriptData.capstoneProjectProgress.course_code_prefix,
                                course_code: transcriptData.capstoneProjectProgress.course_code,
                            };
                            checkWrapping();
                            doc.text(this._formatPlaylistTitle(capstonePlaylist), runningColumnStartX).moveDown(0);

                            transcriptData.sortedCapstoneProjectProgresses.forEach(entry => {
                                checkWrapping();
                                writeEntryWithGrade(entry, true);
                            });

                            doc.moveDown(0.25);
                        }

                        // required streams not in a playlist will be placed under a special top-level category
                        if (transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length > 0) {
                            checkWrapping();
                            doc.text('OTHER REQUIREMENTS', runningColumnStartX).moveDown(0);

                            transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.forEach(entry => {
                                checkWrapping();
                                const markAsPassed = this._omitEntryFromGrade(
                                    transcriptData.preferStrictSmartCaseScore,
                                    transcriptData.foundationsStreamPackIds,
                                    entry,
                                );
                                writeEntryWithGrade(entry, false, markAsPassed);
                            });

                            doc.moveDown(0.25);
                        }

                        doc.moveDown(0.25);
                    }

                    // ***********************************************
                    // Project list
                    // ***********************************************

                    if (transcriptData.sortedNonCapstoneProjectProgresses.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .text(self._formatSectionTitle('PROJECTS', transcriptData), runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedNonCapstoneProjectProgresses.forEach(entry => {
                            checkWrapping();
                            writeEntryWithGrade(entry, true);
                        });

                        doc.moveDown(0.5);
                    }

                    // ***********************************************
                    // Complete specialization courses list
                    //
                    // The list of courses in complete specializations, organized by playlist and in the same order
                    // as cohorts.specialization_playlist_pack_ids.
                    // ***********************************************

                    if (transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .fillColor(textColor)
                            .text(self._formatSectionTitle('SPECIALIZATIONS', transcriptData), runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.forEach(
                            playlistEntry => {
                                checkWrapping();
                                doc.text(
                                    this._formatPlaylistTitle(playlistEntry).toUpperCase(),
                                    runningColumnStartX,
                                ).moveDown(0);

                                playlistEntry.streamEntries.forEach(streamEntry => {
                                    checkWrapping();
                                    const markAsPassed = this._omitEntryFromGrade(
                                        transcriptData.preferStrictSmartCaseScore,
                                        transcriptData.foundationsStreamPackIds,
                                        streamEntry,
                                    );
                                    writeEntryWithGrade(streamEntry, false, markAsPassed);
                                });

                                doc.moveDown(0.25);
                            },
                        );

                        doc.moveDown(0.25);
                    }

                    // ***********************************************
                    // Complete elective courses list
                    //
                    // Basically, the list of complete courses that don't fit into either the course schedule or specialization
                    // ***********************************************

                    if (transcriptData.sortedCompleteElectiveStreamTranscriptEntries.length > 0) {
                        checkWrapping();
                        doc.font('Courier-Bold')
                            .fontSize(LARGER_FONT_SIZE)
                            .text(self._formatSectionTitle('ELECTIVES', transcriptData), runningColumnStartX);

                        doc.font('Courier').fontSize(ENTRY_FONT_SIZE).moveDown(0.25);

                        transcriptData.sortedCompleteElectiveStreamTranscriptEntries.forEach(entry => {
                            checkWrapping();
                            const markAsPassed = this._omitEntryFromGrade(
                                transcriptData.preferStrictSmartCaseScore,
                                transcriptData.foundationsStreamPackIds,
                                entry,
                            );
                            writeEntryWithGrade(entry, false, markAsPassed);
                        });

                        doc.moveDown(0.5);
                    }

                    deferred[i].resolve();
                }

                // back of document
                if (userTranscriptData.backPageImage) {
                    doc.addPage().image(userTranscriptData.backPageImage.data, 0, 0, {
                        width: 612,
                        height: 793,
                    });
                }

                // set the finish event
                pdfStream.on('finish', () => {
                    const blob = pdfStream.toBlob('application/pdf');
                    try {
                        const fileName = `${userTranscriptData.name}_Transcript.pdf`;

                        saveAs(blob, fileName);
                        safeDigest($rootScope);
                    } catch (e) {
                        $injector.get('ErrorLogService').notify('Failed to write certificate blob data to window!', e);
                    }
                });

                $q.all(promises).then(() => doc.end());
            },

            _getEntryFontSize(transcriptData) {
                let entryCount = 0;

                entryCount += transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries.length;
                entryCount += transcriptData.sortedDedupedConcentrationPlaylistTranscriptEntries
                    .map(playlistEntry => playlistEntry.streamEntries)
                    .flat().length;

                if (transcriptData.sortedCapstoneProjectProgresses.length > 0) {
                    entryCount += transcriptData.sortedCapstoneProjectProgresses.length;
                    // add an entry for the fake 'CAPSTONE' playlist title
                    entryCount += 1;
                }

                if (transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length > 0) {
                    entryCount += transcriptData.sortedCompleteRequiredStreamTranscriptEntriesNotInPlaylist.length;
                    // add an entry for the fake 'OTHER REQUIREMENTS' playlist title
                    entryCount += 1;
                }

                entryCount += transcriptData.sortedNonCapstoneProjectProgresses.length;

                entryCount += transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries.length;
                entryCount += transcriptData.sortedDedupedCompletedSpecializationPlaylistTranscriptEntries
                    .map(playlistEntry => playlistEntry.streamEntries)
                    .flat().length;

                entryCount += transcriptData.sortedCompleteElectiveStreamTranscriptEntries.length;

                // Empirical measures as of Jan 4, 2022 that seem to work
                // Note: the current max entries is 148 with *all* content complete for EMBA40+,
                // but this adds an additional breakpoint to give us some headroom just in case.
                // Eventually, we'll want to support breaking this onto a second page.
                if (entryCount > 155) {
                    return 5;
                }
                if (entryCount > 129) {
                    return 6;
                }
                if (entryCount > 112) {
                    return 7;
                }
                return 8;
            },

            _omitEntryFromGrade(preferStrictSmartCaseScore, streamLocalePacksToOmit, streamEntry) {
                return preferStrictSmartCaseScore && !!streamLocalePacksToOmit?.includes(streamEntry?.localePackId);
            },

            _formatScore({ grade = null, isProject = false, isUnpassed = false, isFinalScore = false } = {}) {
                if (isProject) {
                    return isUnpassed ? 'F' : 'P';
                }
                if ([null, undefined].includes(grade) && !isUnpassed && !isFinalScore) {
                    return 'P';
                }
                // We're left with a number grade or a final score, which are formatted the same.
                // Both are expected to be between 0 and 1 inclusive, and are formatted as percentages.
                const score = parseFloat(grade);
                if (Number.isNaN(score) || score < 0 || score > 1) {
                    throw new Error(`score must be a number between 0 and 1 (inclusive). Received: ${grade}`);
                }
                return formatScore(score);
            },

            _formatPlaylistTitle(playlist) {
                if (playlist.course_code_prefix && playlist.course_code) {
                    return `${playlist.course_code_prefix} ${playlist.course_code} - ${playlist.title}`;
                }

                return playlist.title;
            },

            _formatSectionTitle(sectionText, transcriptData) {
                const delimiter = '*';
                const maxTitleLength = 47;
                const titleSansDelimiters = ` ${transcriptData.transcriptSectionTitleDegree.toUpperCase()} ${sectionText} `;
                const numDelimitersNeededPerSide = Math.floor((maxTitleLength - titleSansDelimiters.length) / 2);
                const delimiters = delimiter.repeat(numDelimitersNeededPerSide);
                return `${delimiters}${titleSansDelimiters}${delimiters}`;
            },
        }));
    },
]);
